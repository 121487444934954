.thread-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.thread-item {
  padding: 15px 20px;
  margin: 5px 0;
  background-color: #FFFFFF; /* White background */
  border: 1px solid #DDEEFF; /* Light border for distinction */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */
  cursor: pointer;
}

.thread-item:hover, .thread-item:focus {
  transform: translateY(-2px); /* Slight lift */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover/focus */
}

.create-thread-btn {
  display: block; /* Ensure it takes the full width if necessary */
  width: auto; /* Adjust based on your preference */
  margin: 10px 0; /* More margin around the button */
  padding: 10px 15px;
  background-color: #007BFF; /* Primary color */
  color: #FFFFFF; /* White text */
  border: none;
  border-radius: 8px; /* Rounded corners */
  cursor: pointer;
  font-size: 16px; /* Larger font size */
  font-weight: bold; /* Bold text */
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: background-color 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */
}

.create-thread-btn:hover, .create-thread-btn:focus {
  background-color: #0056b3; /* Slightly darker on hover/focus */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}
