.assistant-list {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.assistant-item {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC; /* Light grey border */
  margin: 10px 0;
  padding: 20px;
  border-radius: 4px; /* Slightly rounded, for a blocky but refined look */
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.assistant-item:not(.disabled):hover {
  background-color: #F0F0F0; /* Slightly darker on hover for interactive feedback */
  transform: translateY(-2px); /* Subtle lift effect */
  border-color: #B0B0B0; /* Darker border on hover */
}

.assistant-item.disabled {
  color: #A0A0A0; /* Greyed out text */
  background-color: #E0E0E0; /* Light grey background */
  border-color: #D0D0D0; /* Matching border */
  cursor: not-allowed; /* Indicate it's not clickable */
}

.assistant-item h3, .assistant-item p {
  margin: 5px 0; /* Spacing inside each item */
}
