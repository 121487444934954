body, html {
  height: 100%;
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #F8F9FA;
  color: #343A40;
}

.app-container {
  display: flex;
  position:fixed;
  height: 100vh;
  z-index: 1;
}

.chat-window, .content-area {
  padding: 20px;
  overflow-y: auto;
}

.chat-window {
  max-width: 75%;
  background-color: #FFFFFF;
  border-right: 1px solid #E0E0E0;
}

.content-area {
  max-width: 25%;
  background-color: #FFFFFF;
}

.chat-container {
  /* Fill the entire page */
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
}

/* Ensure content inside app-container is positioned relative to it */
.chat-container > * {
  position: relative;
  z-index: 1;
}

.chat-window {
  flex: 1;
  border-right: 2px solid #1b5e20; /* Dark green border */
  padding: 20px;
  overflow-y: auto;
}

.messages {
  margin-bottom: 20px;
  overflow-y: auto;
  height: calc(100vh - 100px); /* Adjust for input height */
}

.message {
  padding: 8px 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  font-size: 1rem; /* Adjust font size for better readability */
}

.message.User {
  text-align: right;
  background-color: #2e7d32; /* Dark green background for user messages */
  color: #ffffff; /* White text for contrast */
  border: 1px solid #1b5e20; /* Slightly darker border for depth */
}

.message.AI {
  text-align: left;
  background-color: #32424A;
  border: 1px solid #28393D; /* Slightly darker border for depth */
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 8px 15px;
}

.message.AI a {
  text-decoration: none; /* Removes underline */
  font-weight: bold; /* Makes links bolder */
}

.message.AI a:hover,
.message.AI a:focus {
  text-decoration: underline; /* Adds underline on hover/focus for clarity */
}

@keyframes typingDots {
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
  100% { content: ''; }
}

.typing-placeholder {
  display: inline-block;
  margin-left: 4px;
  font-style: italic;
  /* This will control the speed of the dot animation */
  animation: typingDots 1s infinite step-start;
}

.message.AI em {
  display: inline-block;
  position: relative;
}
.message.AI em::after {
  content: '';
  position: absolute;
  right: -20px; /* Adjust based on your layout */
  animation: typingDots 1.5s infinite;
}

.message-input {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 35%;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.2);
  z-index: 10;
  margin-left: 10px;
  margin-right: 10px;
}

.message-input input {
  flex-grow: 1;
}

.content-area {
  position: relative; /* or 'fixed', depending on your current setup */
  z-index: 1;
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  overflow: hidden; /* Optional, to hide parts of the image that overflow */
}

.content-area img {
  max-width: 100%; /* Ensures the image is not wider than the container */
  max-height: 100%; /* Ensures the image is not taller than the container */
  z-index: 2;
  height: auto; /* Maintain aspect ratio */
  width: auto; /* Maintain aspect ratio */
  display: block; /* Remove extra space below the image */
  margin: 0 auto; /* Center the image if it's smaller than the container */
  position:fixed;
}

.modal {
  position: fixed;
  z-index: 100; /* Ensure it's above other content */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /* Center modal vertically */
  justify-content: center; /* Center modal horizontally */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
}

.modal-content {
  background-color: #333; /* Dark background for futuristic theme */
  z-index: 100; /* Ensure it's above other content */
  margin: auto;
  padding: 20px;
  border: 1px solid #444; /* Slightly lighter border */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  width: 80%;
  max-width: 500px; /* Maximum width */
  color: #ddd; /* Light text */
}

.chat-title h2 {
  cursor: pointer;
}

.chat-title input {
  width: 100%;
}

.create-thread-btn {
  margin-bottom: 10px;
  padding: 8px 16px;
  background-color: #4CAF50; /* Example color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-thread-btn:hover {
  background-color: #45a049;
}

.messages {
  margin-bottom: 20px;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #E9ECEF;
  border-radius: 5px;
}

.message.AI {
  background-color: #DDEEFF;
}

.input-area {
  display: flex;
  justify-content: space-between;
}

.input-area input {
  width: 80%;
  margin-right: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
}

.input-area button {
  background-color: #007BFF;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.input-area button:hover {
  background-color: #0056b3;
}

